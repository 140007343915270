function MyDocument() {
  return (
    <div>
      <iframe
        title="psbc-litepaper"
        style={{ height: "100vh", width: "100vw" }}
        src="https://psbc-prod.s3.amazonaws.com/static/PSBC+Lite+paper.pdf"
      />
    </div>
  );
}

export default MyDocument;
